import { Checkbox } from 'components/FormControls';
import Textarea from 'components/Inputs/Textarea';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ISmsAutoReplyModel, SmsAutoReplyValidateKeyword } from 'types/ISmsAutoReplies';
import { Label } from 'components/Label';
import { toast } from 'react-toastify';
import uuidv4 from 'uuid/v4';
import useSWR from 'swr';
import * as InboxApi from 'api/InboxAPI';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import SubmitButton from 'components/FormControls/SubmitButton';
import { ContentLength } from 'components/EventTemplateForm/ContentLength';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddKeywordModal } from './components/AddKeywordModal';

const SmsAutoReplyEditPage: React.FC = () => {
  const history = useHistory();
  const { replyId } = useParams<{ replyId: string }>();
  const mode = replyId ? 'Update' : 'Create';
  const title = `${mode} SMS Auto-Reply`;
  const [showAddForm, setShowAddForm] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isValidating, setIsValidating] = React.useState<boolean>(false);

  const ACTIVE_CHECKBOX_TOOLTIP = 'Inactive Auto-replies will not send back responses.';
  const KEYWORDS_TOOLTIP = 'Add one or more keywords that will trigger this response. Keyword cannot be already in use by another SMS Auto-Reply. Maximum 5 keywords are allowed.';
  const DESCRIPTION_TOOLTIP = 'A short description that is displayed to recipients when viewing list of all active keywords';
  const RESPONSE_TOOLTIP = 'The content of the message sent to recipients when one of the above keywords is used';
  const VISIBILITY_TOOLTIP = 'Controls which recipients are able to receive this auto-reply. If the recipients phone or mobile number does not correspond to a profile with one of the selected user types in this account, they will not be able to use this keyword.';
  const ANYONE_VISIBILITY_TOOLTIP = 'Enabled for all recipients, even unknown numbers not associated to any profile.';

  useEffect(() => {
    setIsSaving(false);
  }, [setIsSaving]);

  const methods = useForm<ISmsAutoReplyModel>({
    mode: 'onChange',
    defaultValues: {
      SmsAutoReplyId: 0,
      Description: '',
      Response: '',
      IsActive: true,
      StaffVisible: false,
      SeniorVisible: false,
      FamilyVisible: false,
      AnyoneVisible: false,
      Keyword: [],
    },
  });

  const {
    reset,
    handleSubmit,
    setValue,
    getValues,
    control,
    errors,
  } = methods;

  const translateUserType = useUserTypeTranslation();
  const [addKeywordDisabled, setAddKeywordDisbaled] = useState<boolean>(false);

  const { data: dbSmsAutoReply } = useSWR(
    ['smsautoreply', replyId],
    () => InboxApi.getSmsAutoReplyById(replyId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  useEffect(() => {
    if (dbSmsAutoReply) {
      reset(dbSmsAutoReply);
    }
  }, [reset, dbSmsAutoReply]);

  const hasDuplicatedKeyword = (keywords: string[], newKeyword: string) => {
    const dup = keywords.filter((k) => k.toLowerCase() === newKeyword.toLowerCase());
    return dup.length > 0;
  }

  const hasEmojis = (newKeyword: string) => {
    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
    return regex.test(newKeyword);
  }

  const onSubmit = handleSubmit((formData) => {
    handleSave(formData);
  })

  const validateKeywordInUsed = async (newKeyword): Promise<SmsAutoReplyValidateKeyword> => {
    const result = await InboxApi.validateKeywordInUsed(newKeyword);
    return result;
  }

  const handleSave = async (formData) => {
    const submitData: ISmsAutoReplyModel = {
      SmsAutoReplyId: replyId ? parseInt(replyId, 10) : null,
      ...formData,
    }

    try {
      setIsSaving(true);

      if (mode === 'Create') {
        await InboxApi.createSmsAutoReply(submitData);
      } else {
        await InboxApi.updateSmsAutoReply(submitData);
      }
      toast.success('Sms Auto Reply successfully saved.');
      history.push('/sms-auto-reply');
    } catch (error) {
      toast.error(error.response.data.Message);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <div className="SmsAutoReplyEdit">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <FormProvider {...methods}>
        <Form
          className=""
          onSubmit={onSubmit}
        >
          <div className="header-bar">
            <h1>{title}</h1>
          </div>
          <Card>
            <Card.Body>
              <Checkbox
                name="IsActive"
                id="IsActive"
                className="ml-1 sms-auto-reply-text"
                label="Is Active"
                control={control}
                customIcon={(
                  <FontAwesomeIcon icon="question-circle" size="1x" />
                )}
                customIconTooltip={ACTIVE_CHECKBOX_TOOLTIP}
              />
              <br />
              <label className="sms-auto-reply-caption">
                Keywords
                &nbsp;
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id={`tooltip-${uuidv4()}`}>
                      {KEYWORDS_TOOLTIP}
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon="question-circle" size="1x" />
                </OverlayTrigger>
              </label>
              <Controller
                control={control}
                name="Keyword"
                errors={errors?.Keyword && 'Keyword is required'}
                rules={{
                  required: 'Content Required',
                  validate: (val: any) => {
                    if (val === null || val === undefined) {
                      return 'Content Required';
                    }
                    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                    if (regex.test(val)) {
                      return 'Keyword can not include emojis';
                    }
                    return true;
                  },
                }}
                render={({ onChange, onBlur, value }) => (
                  <Card>
                    <Card.Body>
                      {value?.map((k) => (
                        <Label
                          name={k}
                          key={k}
                          showDelete
                          onDelete={() => {
                            const keywords = getValues('Keyword');
                            if (keywords) {
                              const arr = keywords.filter((keyword) => keyword !== k);
                              setValue('Keyword', arr);
                              setAddKeywordDisbaled(arr.length >= 5);
                            }
                          }}
                        />
                      ))}
                    </Card.Body>
                  </Card>
                )}
              />
              <br />
              <button
                type="button"
                className="btn btn-primary square mb-0"
                onClick={() => setShowAddForm(true)}
                disabled={addKeywordDisabled}
              >
                Add Keyword
              </button>
              <br />
              <br />
              <Controller
                errors={errors?.Description && 'Content Required'}
                rules={{
                  required: 'Content Required',
                  validate: (val: any) => {
                    if (val === null || val === undefined || val.trim() === '') {
                      return 'Content Required';
                    }
                    const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                    if (regex.test(val)) {
                      return 'Description can not include emojis';
                    }
                    return true;
                  },
                }}
                control={control}
                name="Description"
                render={({ ref, ...props }) => (
                  <>
                    <ContentLength name="Description" maxLength={120} />
                    <Textarea
                      {...props}
                      id="Description"
                      label="Description"
                      maxLength={120}
                      rows={2}
                      errors={errors?.Description?.message}
                      customIcon={(
                        <FontAwesomeIcon icon="question-circle" size="1x" />
                      )}
                      customIconTooltip={DESCRIPTION_TOOLTIP}
                    />
                  </>
                )}
              />
              <Controller
                errors={errors?.Response && 'Content Required'}
                rules={{
                  required: 'Content Required',
                  validate: (val: any) => {
                    if (val === null || val === undefined || val.trim() === '') {
                      return 'Content Required';
                    }
                    return true;
                  },
                }}
                control={control}
                name="Response"
                render={({ ref, ...props }) => (
                  <>
                    <ContentLength name="Response" maxLength={600} />
                    <Textarea
                      {...props}
                      id="Response"
                      label="Response"
                      maxLength={600}
                      rows={5}
                      errors={errors?.Response?.message}
                      customIcon={(
                        <FontAwesomeIcon icon="question-circle" size="1x" />
                      )}
                      customIconTooltip={RESPONSE_TOOLTIP}
                    />
                  </>
                )}
              />
              <label className="sms-auto-reply-caption">
                Visibility
                &nbsp;
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id={`tooltip-${uuidv4()}`}>
                      {VISIBILITY_TOOLTIP}
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon icon="question-circle" size="1x" />
                </OverlayTrigger>
              </label>
              <br />
              <Checkbox
                name="AnyoneVisible"
                id="AnyoneVisible"
                className="ml-4 sms-auto-reply-text"
                style={{ marginBottom: 10 }}
                label="Anyone"
                customIcon={(
                  <FontAwesomeIcon icon="question-circle" size="1x" />
                )}
                customIconTooltip={ANYONE_VISIBILITY_TOOLTIP}
                control={control}
              />
              <Checkbox
                name="StaffVisible"
                id="StaffVisible"
                className="ml-4 sms-auto-reply-text"
                style={{ marginBottom: 10 }}
                label={translateUserType('Staff')}
                control={control}
              />
              <Checkbox
                name="SeniorVisible"
                id="SeniorVisible"
                className="ml-4 sms-auto-reply-text"
                style={{ marginBottom: 10 }}
                label={translateUserType('Senior')}
                control={control}
              />
              <Checkbox
                name="FamilyVisible"
                id="FamilyVisible"
                className="ml-4 sms-auto-reply-text"
                style={{ marginBottom: 10 }}
                label={translateUserType('Family')}
                control={control}
              />
            </Card.Body>
          </Card>
          <br />
          <SubmitButton
            id="reply-save"
            className="btn btn-primary square mb-0 right"
            label={title}
            savingLabel="Saving..."
            saveComplete={!isSaving}
          />
          {showAddForm && (
            <AddKeywordModal
              disableAddButton={isValidating}
              onSave={async (newKeyword) => {
                setIsValidating(true);
                const available = await validateKeywordInUsed(newKeyword);
                const keywords = getValues('Keyword');
                if (hasDuplicatedKeyword(keywords, newKeyword)) {
                  toast.error(`Keyword ${newKeyword} is already in use.`);
                } else if (available.InUsed) {
                  toast.error(`Keyword ${newKeyword} is already in use.`);
                } else if (available.Reserved) {
                  toast.error("'options', 'menu' and 'guide' are reserved keywords that cannot be used.");
                } else if (hasEmojis(newKeyword)) {
                  toast.error('Keyword cannot contains emojis.');
                } else {
                  keywords.push(newKeyword);
                  setValue('Keyword', keywords);
                  setAddKeywordDisbaled(keywords.length >= 5);
                  setShowAddForm(false);
                }
                setIsValidating(false);
              }}
              onCancel={() => {
                setShowAddForm(false);
              }}
            />
          )}
        </Form>
      </FormProvider>
    </div>
  )
}

export default SmsAutoReplyEditPage;
